import React from 'react'
import { Box, Container, Flex } from 'theme-ui'
import Navigation from '@components/Navigation'
import attachSocialIcons from '@helpers/attachSocialIcons'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
    mt: [5, 6]
  },
  copyright: {
    pt: 2,
    mb: 3,
    fontSize: 3
  }
}

export const Footer = () => {
  const { social } = useSiteMetadata()
  return (
    <Box sx={styles.wrapper}>
      <Container variant='compact' sx={styles.container}>
        <Flex variant='layout.footer'>
          <Box sx={styles.copyright}>
            Ioannis Ioannou © {new Date().getFullYear()}
          </Box>
          <Box>
            <Navigation items={attachSocialIcons(social)} iconOnly/>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}
