import React from 'react'
import { Heading } from 'theme-ui'
import { Link } from 'gatsby'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const styles = {
  title: {
    m: 0
  }
}

export const HeaderLogo = ({ ...props }) => {
  const { title } = useSiteMetadata()
  return (
    <Heading
      as={Link}
      to="/"
      alt={title}
      aria-label={title}
      variant='h2'
      sx={styles.title}
      {...props}
    >
      {title}
    </Heading>
  )
}
